'use client'

import { useEffect } from 'react'

import {
  GoogleTagManager as InstrumentGoogleTagManager,
  sendGTMEvent as nextSendGTMEvent,
} from '@next/third-parties/google'

const getGtmContext = (clickedElement: HTMLElement, attribute: string) => {
  const dataAttr = `data-gtm-${attribute}`
  return clickedElement.closest(`[${dataAttr}]`)?.getAttribute(dataAttr)
}

export function sendGTMEvent(event: Record<string, unknown>) {
  if (process.env.NODE_ENV === 'development') {
    console.debug('Sending GTM event', event)
  }
  nextSendGTMEvent(event)
}

export default function GoogleTagManager({
  gtmId,
  nonce,
}: {
  gtmId: string
  nonce?: string
}) {
  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleClick = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement
      const anchorElement = clickedElement.closest('a')

      // If this click is on an anchor element, we want to send a GTM event
      // unless it is nested inside an element with a data-gtm-ignore attribute
      if (anchorElement && !anchorElement.closest('[data-gtm-ignore]')) {
        const href = anchorElement.getAttribute('href')

        const linkUrl = href
          ? new URL(href, window.location.href).toString()
          : undefined

        const linkText =
          getGtmContext(clickedElement, 'element-text') ||
          anchorElement.textContent?.trim() ||
          anchorElement.querySelector('img')?.alt ||
          undefined

        const eventPosition = getGtmContext(clickedElement, 'event-position')
        const elementType = getGtmContext(clickedElement, 'element-type')

        // TODO: define the default metadata values elsewhere, perhaps in a
        // context provider or with reference to the metadata API. In practice,
        // the page_type defaults to the same value og:title meta tag in the
        // original wordpress implementation. We should revisit whether this is
        // actually the best value to use here.
        const pageType = document.title

        sendGTMEvent({
          event: 'cta_click',
          cta_location: eventPosition || 'body',
          element_type: elementType || 'CTA button',
          page_type: pageType,
          link_text: linkText,
          link_url: linkUrl,
        })
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return <InstrumentGoogleTagManager nonce={nonce} gtmId={gtmId} />
}
