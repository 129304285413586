import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { ddLogger } from '@b-stock/bstock-react'

const logError = (error: Error, info: React.ErrorInfo) => {
  ddLogger.error('Caught error at react error boundary', { info }, error)
}

export interface ErrorBoundaryProps {
  children: React.ReactNode
  onError?: (error: Error, info: React.ErrorInfo) => void
}

const ComponentErrorBoundary = ({ children, onError }: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      fallbackRender={() => null}
      onError={onError || logError}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ComponentErrorBoundary
