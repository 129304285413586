export async function fetchData<T>(url: string): Promise<T> {
  return await fetch(url, {
    next: {
      revalidate: 4,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      return data as T
    })
    .catch((error: unknown) => {
      console.error('Error fetching data:', error)
      throw error
    })
}
