'use client'

import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'

import ComponentErrorBoundary from '@helpers/ComponentErrorBoundary'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import { baseSellerData, type Seller } from './sellerSpotlightData'
import Carousel, { CarouselItemWrapper } from '../common/carousel/Carousel'

interface SellerSpotlightCardProps {
  seller: Seller
}

const StyledSection = styled.section`
  background-color: ${Colors.Semantic.Warning.background};
  padding: 3rem 1.5rem;

  @media ${Breakpoints.min.medium} {
    padding: 4rem 3rem;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: var(--maxPageWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${CarouselItemWrapper} {
    padding: 0 0.3125rem;
  }
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > *:first-child {
    margin-right: auto;
  }
`

const Title = styled.h3`
  ${Typography.Title3};
  color: ${Colors.Semantic.Neutral.light_black};
  padding: 0 0.3125rem;

  @media ${Breakpoints.min.medium} {
    ${Typography.Title2};
  }
`

const ViewAllSellers = styled(ButtonAsA)`
  ${Typography.Body3_Semibold};
  color: ${Colors.Semantic.Neutral.light_black};
  white-space: nowrap;
  padding: 0;

  &&:hover {
    background-color: transparent;
    color: ${Colors.Semantic.Info.highlight};
  }
`

const Card = styled.a<{ $bgColor: string }>`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  width: 20rem;
  padding: 2rem;
  border-radius: 1.5rem;
  ${Shadows.MultipleCards};
  background-color: ${({ $bgColor }) =>
    $bgColor || Colors.Semantic.Neutral.white};
  color: ${Colors.Semantic.Neutral.black};
`

const CardTitle = styled.p`
  ${Typography.Subtitle1};
  color: ${Colors.Semantic.Neutral.black};
`

const SellerLogo = styled(Image)`
  width: 4.6875rem;
  height: 4.6875rem;

  @media ${Breakpoints.min.medium} {
    width: 5.4375rem;
    height: 5.4375rem;
  }
`

const SellerSpotlightCard = ({ seller }: SellerSpotlightCardProps) => {
  const analytics = useAnalytics()

  const handleClickSeller = () => {
    // Segment analytics event and metadata transcribed from old wordpress code
    // TODO: Update entity_type to based on the role of the user once we
    // implement user authentication.
    analytics.trackButtonClicked(
      'homepage', // screen_name
      'homepage_seller', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        url: document.URL,
        referrer: document.referrer,
        seller_name_ua: seller.name.toLowerCase().replace(/\s+/g, '_'),
        cta_url: seller.link,
      }
    )
  }

  return (
    <ComponentErrorBoundary>
      <Card
        $bgColor={seller.color}
        href={seller.link}
        as={seller.link.startsWith('/buy') ? Link : 'a'}
        onClick={handleClickSeller}
        target="_blank"
        rel="noopener"
      >
        <SellerLogo
          src={seller.logo}
          alt={`${seller.name} logo`}
          width={200}
          height={50}
        />
        <CardTitle>{seller.name}</CardTitle>
      </Card>
    </ComponentErrorBoundary>
  )
}

export default function SellerSpotlightCarousel() {
  return (
    <StyledSection>
      <Content>
        <ContentHeader>
          <Title>Seller Spotlight</Title>
          <ViewAllSellers
            size="large"
            appearance="tertiary"
            href="/marketplaces/"
          >
            View All Sellers
          </ViewAllSellers>
        </ContentHeader>
        <Carousel>
          {baseSellerData.map((seller) => (
            <SellerSpotlightCard key={seller.name} seller={seller} />
          ))}
        </Carousel>
      </Content>
    </StyledSection>
  )
}
