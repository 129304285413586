'use client'

import { styled } from 'styled-components'

import { Breakpoints } from '@b-stock/bstock-react/design-system'

const ShopMarketplaceGroup = styled.section`
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  gap: 3rem;

  @media ${Breakpoints.min.medium} {
    padding: 3rem;
  }
`

export default ShopMarketplaceGroup
