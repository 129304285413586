'use client'
// [Other Buying Approaches - WRH-9]
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

const StyledSection = styled.section<{ $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  display: flex;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 769px) {
    padding: 2.5rem 1.5rem;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: var(--maxPageWidth);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
`

const Title = styled.h3`
  ${Typography.Title2};
  color: ${designColors.neutral.veryDarkGray};

  @media screen and (max-width: 769px) {
    font-size: 1.5rem;
  }
`

const CardContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  @media ${Breakpoints.min.medium} {
    flex-direction: row;
    gap: 3rem;
  }
`

const Card = styled.div<{ $bgColor?: string }>`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1.5rem;
  border-radius: 1rem;
  flex: 1;
  max-height: fit-content;
  background-color: ${({ $bgColor }) => $bgColor || designColors.neutral.white};

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    padding: 1.5rem;
  }
`

const StyledImage = styled(Image)`
  object-fit: contain;
  width: 9.25rem;
  height: 9.25rem;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const CardTitle = styled.h4`
  ${Typography.Title3};
  color: ${designColors.neutral.veryDarkGray};
`

const CardText = styled.p`
  ${Typography.Body1};
  color: ${designColors.neutral.veryDarkGray};
  max-width: 22.5rem;
`

const StyledButton = styled(ButtonAsA)`
  width: fit-content;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  white-space: nowrap;

  background-color: ${designColors.neutral.white};
  color: ${designColors.primary.default};

  &&:hover {
    color: ${designColors.neutral.white};
    background-color: ${designColors.primary.default};
  }
`

export default function ExploreBuying() {
  return (
    <StyledSection $bgColor={designColors.neutral.white}>
      <Content>
        <Title>Explore Other Buying Approaches</Title>
        <CardContainer>
          <Card $bgColor={designColors.primary.backgroundLightBlue}>
            <StyledImage
              id="Contracts"
              src="/home-portal/images/contracts.png"
              alt="B-Stock Solutions, LLC."
              width={148}
              height={140}
            />

            <CardContent>
              <CardTitle>Contracts</CardTitle>
              <CardText>
                Shop medium and long-term purchasing contracts directly from the
                world&#39;s largest retailers and manufacturers
              </CardText>
            </CardContent>

            <StyledButton
              appearance="primary"
              size="large"
              href="/direct/"
              target="_blank"
              rel="noopener"
            >
              Start Shopping <FontAwesomeIcon icon={faLongArrowRight} />
            </StyledButton>
          </Card>
          <Card $bgColor="#FFF9E5">
            <StyledImage
              id="Spot Sales"
              src="/home-portal/images/flash_sales.png"
              alt="B-Stock Solutions, LLC."
              width={148}
              height={140}
            />

            <CardContent>
              <CardTitle>Spot Sales</CardTitle>
              <CardText>
                Browse one-time offers from Fortune 500 retailers and
                manufacturers. Sizes range from parcels and single pallets to
                full truckloads
              </CardText>
            </CardContent>

            <StyledButton
              appearance="primary"
              size="large"
              href="/all-auctions/"
              target="_blank"
              rel="noopener"
            >
              Start Shopping <FontAwesomeIcon icon={faLongArrowRight} />
            </StyledButton>
          </Card>
        </CardContainer>
      </Content>
    </StyledSection>
  )
}
