'use client'

import {
  faBan,
  faBoxesStacked,
  faUserGroup,
  faWindowMaximize,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

const StyledSection = styled.section`
  background: ${Colors.Semantic.Neutral.light_grey};
  padding: 3rem 1.5rem;

  @media ${Breakpoints.min.medium} {
    padding: 4rem 3rem;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: var(--maxPageWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
`

const PreTitle = styled.h2`
  ${Typography.Subtitle2};
  color: ${Colors.Semantic.Info.highlight};
  text-transform: uppercase;
  letter-spacing: 0.07rem;
`

const Title = styled.h3`
  ${Typography.Title3};
  color: ${Colors.Semantic.Neutral.light_black};

  @media ${Breakpoints.min.medium} {
    ${Typography.Title2};
  }
`

const CardContainer = styled.div`
  display: grid;
  gap: 3rem;

  @media ${Breakpoints.min.medium} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${Breakpoints.min.large} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
  text-align: center;
  color: ${Colors.Semantic.Neutral.light_black};
`

export const StyledFAIcon = styled(FontAwesomeIcon)<{ $flipped?: boolean }>`
  color: ${Colors.Semantic.Info.highlight};
  width: 2.5rem;
  height: 2.5rem;
  transform: ${({ $flipped }) => ($flipped ? 'scaleX(-1)' : 'none')};
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CardTitle = styled.h4`
  ${Typography.Subtitle1};
`

const CardText = styled.p`
  ${Typography.Body2};
`

const cards = [
  {
    icon: faWindowMaximize,
    title: 'No middleman',
    text: 'Buy direct from retailers through their official liquidation marketplaces.',
  },
  {
    icon: faBoxesStacked,
    title: 'All lot sizes, categories',
    text: 'Source across all lot sizes, product categories, and price points.',
  },
  {
    icon: faBan,
    iconFlipped: true,
    title: 'No surprises',
    text: "From detailed listings to itemized manifests, you'll know the quantity.",
  },
  {
    icon: faUserGroup,
    iconFlipped: true,
    title: "We've got your back!",
    text: 'A dedicated customer service team and education center to help you grow your business.',
  },
]

export default function BestPlaceToPurchase() {
  return (
    <StyledSection>
      <Content>
        <Titles>
          <PreTitle>Source Inventory for Sale</PreTitle>
          <Title>B-Stock: The best place to find inventory for resale</Title>
        </Titles>
        <CardContainer>
          {cards.map(({ icon, iconFlipped, title, text }) => (
            <Card key={title}>
              <StyledFAIcon icon={icon} $flipped={iconFlipped} />
              <CardContent>
                <CardTitle>{title}</CardTitle>
                <CardText>{text}</CardText>
              </CardContent>
            </Card>
          ))}
        </CardContainer>
      </Content>
    </StyledSection>
  )
}
