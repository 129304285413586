import { useQuery } from '@tanstack/react-query'

import type {
  AllListings200Response,
  AllListingsListing,
} from '@b-stock/search-api-client'

import { fetchData } from './api/fetchAuctionData'
import { parseCustomDate } from './dateUtils'

const ONE_MINUTE = 60 * 1000 // 1 minute in milliseconds
const SEVEN_MINUTES = 7 * ONE_MINUTE
const TEN_MINUTES = 10 * ONE_MINUTE

// Filter out expired auctions that are within 10 minutes of their end time
function filterExpiredAuctions(auctions: AllListingsListing[]) {
  const now = Date.now()
  return auctions.filter((auction) => {
    const endTimeMs = parseCustomDate(auction.endTime).getTime()
    return endTimeMs - now >= TEN_MINUTES
  })
}

// Deduplicate auctions by auctionId when there are multiple auction queries
// being merged together.
function mergeUniqueAuctions(
  auctionSets: AllListingsListing[][]
): AllListingsListing[] {
  // If there's only one auction set, we already know there are no duplicates
  if (auctionSets.length === 1) {
    return auctionSets[0]
  }

  const uniqueAuctions = [...auctionSets[0]]
  const uniqueAuctionIds = new Set(
    uniqueAuctions.map((auction) => auction.auctionId)
  )
  for (let i = 1; i < auctionSets.length; i++) {
    for (const auction of auctionSets[i]) {
      if (!uniqueAuctionIds.has(auction.auctionId)) {
        uniqueAuctions.push(auction)
        uniqueAuctionIds.add(auction.auctionId)
      }
    }
  }

  return uniqueAuctions
}

// Generates a query object for fetching auctions which can be passed into
// useQuery, useSuspenseQuery, or prefetchQuery
export function auctionQuery(endpoint: string | string[]) {
  return {
    queryKey: ['auctions', endpoint],
    queryFn: async () => {
      const endpoints = Array.isArray(endpoint) ? endpoint : [endpoint]
      const queryPromises = endpoints.map((endpoint) =>
        fetchData<AllListings200Response>(endpoint).then((data) => {
          return filterExpiredAuctions(data.listings)
        })
      )
      const auctionSets = await Promise.all(queryPromises)

      return mergeUniqueAuctions(auctionSets)
    },
    // staleTime must be non-zero otherwise prefetched data will be immediately
    // refetched client-side
    staleTime: ONE_MINUTE,
    refetchInterval: SEVEN_MINUTES,
    refetchIntervalInBackground: true,
  }
}

export function useAuctionQuery(endpoint: string | string[]) {
  return useQuery(auctionQuery(endpoint))
}
