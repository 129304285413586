'use client'
import { styled } from 'styled-components'

import { Colors } from '@b-stock/bstock-react/design-system'

const Wrapper = styled.div`
  background-color: ${Colors.Semantic.Neutral.white};
  border-radius: 0.5rem;
  transition: all 0.3s;
  width: 14.75rem;
  text-decoration: none;
  overflow: hidden;
`

const BaseSkeleton = styled.div`
  border-radius: 0.25rem;
  position: relative;
  background: linear-gradient(
    270deg,
    rgba(219, 219, 219, 0.05) 0%,
    #e6e6e6 100%
  );
  animation: pulse 1s ease-in-out 0.5s infinite;
  -webkit-animation: pulse 1s ease-in-out 0.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`

const AuctionImageHolder = styled(BaseSkeleton)`
  height: 10.5rem;
`

const LotInfo = styled.div`
  width: 100%;
  padding: 0.75rem;
  background-color: ${Colors.Semantic.Neutral.white};
`

const SkeletonLargeText = styled(BaseSkeleton)`
  height: 3rem;
  display: flex;
`

const SkeletonTextTypeOne = styled.div`
  margin-top: 0.5rem;
  display: flex;

  div {
    width: 6rem;
    height: 1.375rem;
    border-radius: 0.25rem;
  }
`

const SkeletonTextTypeTwo = styled(BaseSkeleton)`
  border-radius: 0.25rem;
  height: 1.625rem;
  margin-top: 0.25rem;
`

const SkeletonTextTypeThree = styled.div`
  display: flex;
  margin-top: 0.25rem;

  div {
    border-radius: 0.25rem;
    height: 1.625rem;
    width: 10rem;
  }
`

const AuctionCardSkeleton = () => {
  return (
    <Wrapper>
      <AuctionImageHolder />
      <LotInfo>
        <SkeletonLargeText />
        <SkeletonTextTypeOne>
          <BaseSkeleton />
        </SkeletonTextTypeOne>
        <SkeletonTextTypeOne>
          <BaseSkeleton />
        </SkeletonTextTypeOne>
        <SkeletonTextTypeTwo />
        <SkeletonTextTypeThree>
          <BaseSkeleton />
        </SkeletonTextTypeThree>
      </LotInfo>
    </Wrapper>
  )
}

export default AuctionCardSkeleton
